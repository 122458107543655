import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [{
  path: '*',
  redirect: '/home'
},
{
  name: 'login',
  component: () => import('./view/login'),
  meta: {
    title: '登录页',
  }
},
{
  name: 'home',
  component: () => import('./view/home'),
  meta: {
    title: '首页',
    requireAuth: true
  }
},
{
  name: 'company',
  component: () => import('./view/company'),
  meta: {
    title: 'GOLANG ROADMAP·Go公司',
    requireAuth: false
  }
},
{
  name: 'post',
  component: () => import('./view/postlist'),
  meta: {
    title: 'GOLANG ROADMAP·Go岗位',
    requireAuth: false
  }
},
{
  name: 'sharepost',
  component: () => import('./view/sharepost'),
  meta: {
    title: 'GOLANG ROADMAP·Go岗位',
    requireAuth: false
  }
},
{
  name: 'sharequestion',
  component: () => import('./view/sharequestion'),
  meta: {
    title: 'GOLANG ROADMAP·面试宝典',
    requireAuth: false
  }
},
{
  name: 'shareblog',
  component: () => import('./view/shareblog'),
  meta: {
    title: 'GOLANG ROADMAP·Go博文',
    requireAuth: false
  }
},
{
  name: 'sharevideo',
  component: () => import('./view/sharevideo'),
  meta: {
    title: 'GOLANG ROADMAP·Go视界',
    requireAuth: false
  }
},
{
  name: 'product',
  component: () => import('./view/product'),
  meta: {
    title: '服务列表',
    requireAuth: true
  }
},
{
  name: 'question',
  component: () => import('./view/question'),
  meta: {
    title: '面试宝典',
    requireAuth: true
  }
},
{
  name: 'golang',
  component: () => import('./view/golang'),
  meta: {
    title: 'Golang-面试宝典',
    requireAuth: true
  }
},
{
  name: 'algorithm',
  component: () => import('./view/algorithm'),
  meta: {
    title: '数据结构与算法-面试宝典',
    requireAuth: true
  }
},
{
  name: 'database',
  component: () => import('./view/database'),
  meta: {
    title: '数据库-面试宝典',
    requireAuth: true
  }
},
{
  name: 'network',
  component: () => import('./view/network'),
  meta: {
    title: '网络-面试宝典',
    requireAuth: true
  }
},
{
  name: 'tools',
  component: () => import('./view/tools'),
  meta: {
    title: '工具-面试宝典',
    requireAuth: true
  }
},
{
  name: 'os',
  component: () => import('./view/os'),
  meta: {
    title: '操作系统-面试宝典',
    requireAuth: true
  }
},
{
  name: 'architecture',
  component: () => import('./view/architecture'),
  meta: {
    title: '系统设计-面试宝典',
    requireAuth: true
  }
},
{
  name: 'detail',
  component: () => import('./view/detail'),
  meta: {
    title: '题目信息',
    requireAuth: true
  }
},
{
  name: 'user',
  component: () => import('./view/user'),
  meta: {
    title: '会员中心',
    requireAuth: true
  }
},
{
  name: 'vip',
  component: () => import('./view/vip'),
  meta: {
    title: '购买会员'
  }
},
{
  name: 'resume',
  component: () => import('./view/resume'),
  meta: {
    title: '我的简历'
  }
},
{
  name: 'collectiondetail',
  component: () => import('./view/collectiondetail'),
  meta: {
    title: 'Go合集'
  }
},
{
  name: 'collection',
  component: () => import('./view/collectionlist'),
  meta: {
    title: 'Go合集'
  }
},
{
  name: 'class',
  component: () => import('./view/classlist'),
  meta: {
    title: 'Go课程',
    requireAuth: false
  }
}
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};