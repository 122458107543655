import Vue from 'vue';
import App from './App';
import axios from 'axios';
import VueClipboard from 'vue-clipboard2';
import { Lazyload } from 'vant';

Vue.use(Lazyload);

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)






// http request 拦截器
axios.interceptors.request.use(
  config => {
    var token = window.localStorage.getItem("auth-token")
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });



import {
  router
} from './router';

//百度统计
// eslint-disable-next-line
const _hmt = _hmt || [];

// eslint-disable-next-line
window._hmt = _hmt;
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?4c7fb9542086e8db1c915adf3fc31e50";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  /**
   * 未登录则跳转到登录页
   * 未登录跳转到登录页,也可以通过axios的响应拦截器去实现,但是会先在当前页面渲染一下,再跳转到登录页,会有个闪动的现象
   * 这里通过路由守卫的方式,不会在当前页闪现一下,但是需要在每个路由组件添加一个是否需要登录的标识位,如本项目中的requireAuth字段
   */
  if (to.matched.some((auth) => auth.meta.requireAuth)) {
    let token = window.localStorage.getItem("auth-token")
    if (window._hmt) {
      if (to.path) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      }
    }
    if (token) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next();
  }

})

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});